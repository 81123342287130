<!-------------------------------------------------
description:  发票管理 退回原因 弹窗组件
/**
 *@author xiaosanye 
 *@date 2023/2/11
*/
--------------------------------------------------->
<template>
  <ykc-operation-dialog
    title="退回"
    :before-close="onCLose"
    :show.sync="showReturnDialog"
    :before-confirm="submitForm"
    :before-cancel="beforeCancel1">
    <div slot="content">
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <ykc-form-item label="退回原因" prop="reason">
          <ykc-input v-model="ruleForm.reason" type="textarea"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-operation-dialog>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { invoiceManagements } from '@/service/apis';
  import YkcOperationDialog from '@/components/ykc-ui/operation-dialog/src/main.vue';

  export default {
    name: 'ReturnDialog',
    components: { YkcOperationDialog },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      row: { type: Object, default: () => ({}) },
    },
    data() {
      return {
        showReturnDialog: false,
        ruleForm: {
          reason: '',
          id: '',
        },
        rules: {
          reason: [
            {
              required: true,
              message: regexpObj.regexp.input.textAreaType.errorTips.empty('退回原因'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('退回原因'),
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {
      this.ruleForm.id = this.row.id;
    },
    watch: {
      show(newValue, oldValue) {
        console.log('oldValue:', oldValue);
        this.showReturnDialog = newValue;
      },
      row(newValue, oldValue) {
        console.log('oldValue:', oldValue);
        this.ruleForm.id = newValue?.id;
      },
    },
    methods: {
      onCLose() {
        // this.showRejectDialog = false;
        this.$emit('close-return-dialog');
      },
      beforeCancel1(done) {
        console.log('取消的回调 dialog1');
        this.$emit('close-return-dialog');
        done();
      },
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              // todo  校验规则正确后 请求接口
              invoiceManagements
                .doReturn(this.ruleForm)
                .then(res => {
                  console.log('-> res', res);
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  this.$emit('refresh-list');
                })
                .catch(err => {
                  console.error(err);
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
          }
        });
      },
    },
  };
</script>

<style scoped></style>
