<template>
  <div class="apply-for-billing">
    <ykc-drawer
      title="申请开票"
      show-dialog-when-cancel
      :show.sync="show6"
      :before-ensure="beforeDrawer3Save"
      @dialog-confirm-click="drawer6DialogConfirmClick">
      <template>
        <div class="drawer-main-body">
          <ykc-form :model="ruleForm" :rules="dynamicRules" ref="ruleForm">
            <el-divider content-position="left">申请信息</el-divider>
            <ykc-form-item label="选择客户" prop="customerId">
              <ykc-dropdown
                :data="customerData"
                :multiple="false"
                :remote="true"
                :placeholder="regexpObj.regexp.associativeInput.errorTips.empty()"
                @change="onCustomerChange"
                v-model="ruleForm.customerId"></ykc-dropdown>
            </ykc-form-item>
            <ykc-form-item label="订单时间范围" prop="dateRange">
              <ykc-date-picker
                @change="onOrderDateChange"
                v-model="ruleForm.dateRange"
                type="daterange"></ykc-date-picker>
            </ykc-form-item>
            <ykc-form-item label="开票金额（元）" prop="applyAmount">
              <ykc-input disabled readonly v-model="ruleForm.applyAmount">
                <template slot="append">元</template>
              </ykc-input>
            </ykc-form-item>
            <el-divider content-position="left">发票属性</el-divider>
            <ykc-form-item label="发票种类" prop="invoiceType">
              <ykc-radio
                :data="[
                  { id: '1', name: '增值税普通发票' },
                  { id: '2', name: '增值税专用发票' },
                ]"
                v-model="ruleForm.invoiceType"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item label="发票形式" prop="invoiceSituation">
              <ykc-radio
                :data="[
                  { id: '1', name: '纸质发票' },
                  { id: '2', name: '电子发票' },
                ]"
                v-model="ruleForm.invoiceSituation"></ykc-radio>
            </ykc-form-item>
            <ykc-form-item label="发票抬头" prop="invoiceTitle">
              <ykc-input v-model="ruleForm.invoiceTitle"></ykc-input>
            </ykc-form-item>
            <ykc-form-item label="税号" prop="taxNo">
              <ykc-input v-model="ruleForm.taxNo"></ykc-input>
            </ykc-form-item>
            <div v-if="ruleForm.invoiceType === '2'" class="special-ticket-filling">
              <div class="corner-tip">专票填写</div>
              <ykc-form-item label="开户行名称" prop="bankName">
                <ykc-input v-model="ruleForm.bankName"></ykc-input>
              </ykc-form-item>
              <ykc-form-item label="开户账号" prop="bankCard">
                <ykc-input v-model="ruleForm.bankCard"></ykc-input>
              </ykc-form-item>
              <ykc-form-item label="地址" prop="regAddress">
                <ykc-input v-model="ruleForm.regAddress"></ykc-input>
              </ykc-form-item>
              <ykc-form-item label="电话" prop="regPhone">
                <ykc-input v-model="ruleForm.regPhone"></ykc-input>
              </ykc-form-item>
            </div>
            <el-divider content-position="left">收件信息</el-divider>
            <ykc-form-item
              v-if="ruleForm.invoiceSituation === '1'"
              label="收件人"
              prop="consigneeName">
              <ykc-input v-model="ruleForm.consigneeName"></ykc-input>
            </ykc-form-item>
            <ykc-form-item
              v-if="ruleForm.invoiceSituation === '1'"
              label="收件电话"
              prop="consigneePhone">
              <ykc-input v-model="ruleForm.consigneePhone"></ykc-input>
            </ykc-form-item>
            <ykc-form-item
              v-if="ruleForm.invoiceSituation === '1'"
              label="收件地址"
              prop="consigneeAddress">
              <ykc-input v-model="ruleForm.consigneeAddress" type="textarea"></ykc-input>
            </ykc-form-item>
            <ykc-form-item
              v-if="ruleForm.invoiceSituation === '2'"
              label="收件邮箱"
              prop="consigneeMail">
              <ykc-input v-model="ruleForm.consigneeMail"></ykc-input>
            </ykc-form-item>
          </ykc-form>
        </div>
      </template>
    </ykc-drawer>
  </div>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { invoiceManagements } from '@/service/apis';

  export default {
    name: 'ApplyForBilling',
    components: {},
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      customerData: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        show6: this.show,
        regexpObj,
        bSideAmount: '', // B端可开票金额
        ruleForm: {
          // 发票种类
          invoiceType: '1',
          // 发票形式
          invoiceSituation: '1',
          // 发票抬头
          invoiceTitle: '',
          // 税号
          taxNo: '',
          // 开户行名称
          bankName: '',
          // 开户账号
          bankCard: '',
          // 地址
          regAddress: '',
          // 电话 座机
          regPhone: '',
          customerId: '', // 已选择的客户
          orderStartTime: '', // 订单时间开始
          orderEndTime: '', // 订单时间结束
          applyAmount: '0', // 开票金额
          //   收件人
          consigneeName: '',
          //   收件人手机号
          consigneePhone: '',
          //   收件地址
          consigneeAddress: '',
          //   收件邮箱
          consigneeMail: '',
          // 时间范围
          dateRange: [],
        },
      };
    },
    watch: {
      show(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        this.show6 = this.show;
      },
    },
    computed: {
      dynamicRules() {
        // 定义申请信息模块规则
        const applyInfoRule = {
          // customerId 选择客户
          customerId: [{ required: true, message: '请选择客户', trigger: 'change' }],
          // dateRange  订单时间范围
          dateRange: [{ type: 'array', required: true, message: '请选择时间', trigger: 'change' }],
          // applyAmount 开票金额（元）
          // 发票金额
          applyAmount: [
            {
              required: true,
              message: regexpObj.regexp.input.amountNumberType.errorTips.empty(),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.amountNumberType.regexp,
              message: regexpObj.regexp.input.amountNumberType.errorTips.error(),
              trigger: 'blur',
            },
          ],
        };
        // 定义发票属性模块规则
        const invoicePropertyBaseRule = {
          // 发票类型
          invoiceType: [{ required: true, message: '请输入发票类型', trigger: 'blur' }],
          // 发票形式
          invoiceSituation: [{ required: true, message: '请输入发票形式', trigger: 'blur' }],
          // 发票抬头
          invoiceTitle: [
            {
              required: true,
              message: regexpObj.regexp.input.inlineTextType.errorTips.empty('发票抬头'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('发票抬头'),
              trigger: 'blur',
            },
          ],
          // 税号
          taxNo: [
            {
              required: true,
              message: regexpObj.regexp.input.accountType.errorTips.empty('税号'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('税号'),
              trigger: 'blur',
            },
          ],
        };
        // 发票专票属性
        const invoicePropertySpecRule = {
          // 开户行名称
          bankName: [
            {
              required: true,
              message: regexpObj.regexp.input.nameType.errorTips.empty('开户行名称'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('开户行名称'),
              trigger: 'blur',
            },
          ],
          // 开户账号
          bankCard: [
            {
              required: true,
              message: regexpObj.regexp.input.accountType.errorTips.empty('开户账号'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.accountType.regexp,
              message: regexpObj.regexp.input.accountType.errorTips.error('开户账号'),
              trigger: 'blur',
            },
          ],
          // 地址
          regAddress: [
            {
              required: true,
              message: regexpObj.regexp.input.addressType.errorTips.empty('地址'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('地址'),
              trigger: 'blur',
            },
          ],
          // 座机
          regPhone: [
            {
              required: true,
              message: regexpObj.regexp.input.landlinesType.errorTips.empty(),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.landlinesType.regexp,
              message: regexpObj.regexp.input.landlinesType.errorTips.error(),
              trigger: 'blur',
            },
          ],
        };
        // 发票属性相关
        const invoicePropertyRule =
          this.ruleForm.invoiceType === '1'
            ? invoicePropertyBaseRule
            : { ...invoicePropertyBaseRule, ...invoicePropertySpecRule };
        // 定义收件信息模块规则
        const paperRules = {
          // 收件人
          consigneeName: [
            {
              required: true,
              message: regexpObj.regexp.input.nameType.errorTips.empty('收件人名称'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('收件人名称'),
              trigger: 'blur',
            },
          ],
          // 收件电话
          consigneePhone: [
            {
              required: true,
              message: regexpObj.regexp.input.cellphoneType.errorTips.empty('收件电话'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.cellphoneType.regexp,
              message: regexpObj.regexp.input.cellphoneType.errorTips.error('收件电话'),
              trigger: 'blur',
            },
          ],
          // 收件地址
          consigneeAddress: [
            {
              required: true,
              message: regexpObj.regexp.input.addressType.errorTips.empty('收件地址'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.addressType.regexp,
              message: regexpObj.regexp.input.addressType.errorTips.error('收件地址'),
              trigger: 'blur',
            },
          ],
        };
        const emailRules = {
          // 收件邮箱
          consigneeMail: [
            {
              required: true,
              message: regexpObj.regexp.input.emailType.errorTips.empty('收件邮箱'),
              trigger: 'blur',
            },
            {
              pattern: regexpObj.regexp.input.emailType.regexp,
              message: regexpObj.regexp.input.emailType.errorTips.error,
              trigger: 'blur',
            },
          ],
        };
        const receiveInfoRule = this.ruleForm.invoiceSituation === '1' ? paperRules : emailRules;
        return {
          ...applyInfoRule,
          ...receiveInfoRule,
          ...invoicePropertyRule,
        };
      },
    },
    created() {},
    methods: {
      resetFormData() {
        console.log('reset form data');
        this.$refs.ruleForm.clearValidate();
        const resetData = {
          // 发票种类
          invoiceType: '1',
          // 发票形式
          invoiceSituation: '1',
          // 发票抬头
          invoiceTitle: '',
          // 税号
          taxNo: '',
          // 开户行名称
          bankName: '',
          // 开户账号
          bankCard: '',
          // 地址
          regAddress: '',
          // 电话 座机
          regPhone: '',
          customerId: '', // 已选择的客户
          orderStartTime: '', // 订单时间开始
          orderEndTime: '', // 订单时间结束
          applyAmount: '0', // 开票金额
          //   收件人
          consigneeName: '',
          //   收件人手机号
          consigneePhone: '',
          //   收件地址
          consigneeAddress: '',
          //   收件邮箱
          consigneeMail: '',
          // 时间范围
          dateRange: [],
        };
        this.ruleForm = {
          ...this.ruleForm,
          ...resetData,
        };
      },
      submitForm(done) {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            this.formValid = true;
            try {
              invoiceManagements
                .doBSideApply(this.ruleForm)
                .then(res => {
                  console.log('-> doBSideApply res', res);
                  this.$message({
                    message: '提交成功',
                    type: 'success',
                  });
                  setTimeout(() => {
                    this.$emit('refresh-list');
                    done();
                  }, 1000);
                })
                .catch(err => {
                  console.error(err);
                });
            } catch (e) {
              console.error(e);
            }
          } else {
            console.error('error submit!!');
            this.formValid = false;
          }
        });
      },
      onCustomerChange(e) {
        console.log('val:', e);
        if (this.ruleForm.dateRange.length && this.ruleForm.customerId) {
          this.getBSideAmount(this.ruleForm);
        }
      },
      /**
       * @param {string} customerId 客户ID
       * @param {string} orderStartTime 开始时间
       * @param {string} orderEndTime 结束时间
       * */
      getBSideAmount({ customerId, orderStartTime, orderEndTime }) {
        invoiceManagements
          .getBSideApplyAmount({
            customerId,
            orderStartTime,
            orderEndTime,
          })
          .then(res => {
            // eslint-disable-next-line no-unused-vars
            this.bSideAmount = res?.applyAmount || '0';
            this.ruleForm.applyAmount = res?.applyAmount || '0';
          });
      },

      onOrderDateChange(e) {
        console.log('val:', e);
        if (e?.date) {
          this.ruleForm.orderStartTime = e?.date?.[0];
          this.ruleForm.orderEndTime = e?.date?.[1];
          if (this.ruleForm.customerId && this.ruleForm.dateRange.length) {
            this.getBSideAmount(this.ruleForm);
          }
        }
      },
      beforeDrawer3Save(done) {
        console.log('done', done);
        this.submitForm(done);
      },
      drawer6DialogConfirmClick() {
        console.log('close apply for billing dialog confirm btn clicked');
        // this.resetFormData();
        this.$emit('drawer-close');
      },
    },
  };
</script>

<style scoped lang="scss">
  .special-ticket-filling {
    position: relative;
    border-color: #e50d8e;
    border-radius: 5px;
    border-style: dotted;
    border-width: 1px;
    padding: 5px;
    .corner-tip {
      position: absolute;
      right: 0;
      top: 0;
      color: #e50d8e;
    }
  }
</style>
